<template>
    <b-modal id="enquiryForm" size="sm" centered  header-bg-variant="light" header-text-variant="dark" title="Enter Your Details" hide-footer>
        <b-form @submit="onSubmit">
            <b-form-input  v-model="enquiryForm.first_name" placeholder="Your Name" type="text" required></b-form-input>
            <b-form-input  v-model="enquiryForm.phone" placeholder="Your Mobile Number" type="number" required></b-form-input>
            <b-form-input  class="mb-3" v-model="enquiryForm.email" placeholder="Your Email " type="email" required></b-form-input>
            <div class="error-msg mb-3">
            </div>
            <b-button type="submit" variant="primary" block>Submit</b-button>
        </b-form>
  </b-modal>
</template>

<script>
import {enquiryFormUrl} from '@/assets/scripts/utility.js';
  export default {
    data() {
      return {
        enquiryForm: {
          first_name: '',
          phone: '',
          email: '',
          channel_partner_id: '' 
        }
      }
    },
    mounted() {
      if(this.$route.query.user){
        this.enquiryForm.channel_partner_id = atob(this.$route.query.user);
      }
    },
    methods: {
      onSubmit(event) {
        event.preventDefault();
        this.$axios.defaults.headers.common['ngrok-skip-browser-warning'] = true;
        this.$axios.post(enquiryFormUrl, this.enquiryForm).then((res)=> {
          if(res.status === 200){
            let userId = btoa(res.data.user_id);
            this.$router.replace({query: {user:userId}});
            this.$store.dispatch('setUserId',userId);
            this.$cookies.set('nr-user-id',userId,'1d');
            this.$bvModal.hide('enquiryForm')
          }
        }).catch((err)=>{
          if(err.response.status === 400){
            $('.error-msg').html(`<span>Contact information provided is already in use</span>`);
          }
        });
      }
    }
  }
</script>

<style scoped>
.error-msg{
  font-size: 12px;
  color: #dc3545;
  text-align: center;
}
</style>